export default {
    getBase64(file) {
        return new Promise(function (resolve, reject) {
            const reader = new FileReader()
            let imgResult = ''
            reader.readAsDataURL(file)
            reader.onload = function () {
                imgResult = reader.result
            }
            reader.onerror = function (error) {
                reject(error)
            }
            reader.onloadend = function () {
                resolve(imgResult)
            }
        })
    },

    getAreaValue(regionData, addrProvince, addrCity, addrDistrict) {
        let valueAry = [];
        for(let i = 0; i < regionData.length; i++) {
            if(addrProvince == regionData[i].label) {
                valueAry[0] = regionData[i].value;
                let cityChildren = regionData[i].children;
                for(let j = 0; j < cityChildren.length; j++) {
                    if (addrCity == cityChildren[j].label) {
                        valueAry[1] = cityChildren[j].value;
                        let districtChildren = cityChildren[j].children;
                        for(let k = 0; k < districtChildren.length; k++) {
                            if (addrDistrict == districtChildren[k].label) {
                                valueAry[2] = districtChildren[k].value;
                            }
                        }
                    }
                }
                break;
            }
        }
        return valueAry;
    },

    covTimestamp(time) {
        var  timdedetail = time;//假如是10位毫秒需要乘1000
        var year=new Date(timdedetail).getFullYear();
        var month=new Date(timdedetail).getMonth()+1;
        if(month<10){
            month="0"+month;
        }
        var date=new Date(timdedetail).getDate();
        if(date<10){
            date="0"+date;
        }
        var h1=new Date(timdedetail).getHours()<10?'0'+new Date(timdedetail).getHours():new Date(timdedetail).getHours();
        var m1=new Date(timdedetail).getMinutes()<10?'0'+new Date(timdedetail).getMinutes():new Date(timdedetail).getMinutes();
        var s1=new Date(timdedetail).getSeconds()<10?'0'+new Date(timdedetail).getSeconds():new Date(timdedetail).getSeconds();
        return year+"-"+month+"-"+date+" "+h1+":"+m1+":"+s1;
    },

    covTimeStr(time) {
        var  timdedetail = time;//假如是10位毫秒需要乘1000
        var year=new Date(timdedetail).getFullYear();
        var month=new Date(timdedetail).getMonth()+1;
        if(month<10){
            month="0"+month;
        }
        var date=new Date(timdedetail).getDate();
        if(date<10){
            date="0"+date;
        }
        var h1=new Date(timdedetail).getHours()<10?'0'+new Date(timdedetail).getHours():new Date(timdedetail).getHours();
        var m1=new Date(timdedetail).getMinutes()<10?'0'+new Date(timdedetail).getMinutes():new Date(timdedetail).getMinutes();
        var s1=new Date(timdedetail).getSeconds()<10?'0'+new Date(timdedetail).getSeconds():new Date(timdedetail).getSeconds();
        return year+"-"+month+"-"+date;
    },

    covImageFileList(imageList) {
        var fileList = [];
        for(let i = 0; i < imageList.length; i++) {
            fileList.push(imageList[i].filePath);
        }
        return fileList;
    },

    setLocalInfo(LocalInfo) {
        if(LocalStorage.get("userInfo") != undefined) {
            LocalStorage.set("localInfo_" + LocalStorage.get("userInfo").id, LocalInfo);
        }
    },

    getLocalInfo() {
        if(LocalStorage.get("userInfo") != undefined) {
            return LocalStorage.get("localInfo_" + LocalStorage.get("userInfo").id);
        }
        return null;
    },

    addSession(currentPage) {
        window.sessionStorage.setItem('currentPage', JSON.stringify(currentPage));
    },

    getSession(currentPage) {
        return window.sessionStorage.getItem(currentPage);
    },

    removeSession() {
        window.sessionStorage.removeItem('currentPage');
    },

    trimSpace(str) {
        return str.replace(/(^\s*)|(\s*$)/g, "");
    },

    checkPhone(phone) {
        const phoneReg = /^\d{11}$/;
        if(phoneReg.test(phone)) {
            return true;
        }
        return false;
    }
}
