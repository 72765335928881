import Vue from 'vue'
import VueRouter from 'vue-router'
import VueWechatTitle from 'vue-wechat-title';

Vue.use(VueWechatTitle)
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import( '../views/login.vue'),
        meta:{
            title:'Redmine'
        }//登入页面
    },

    {
        path: '/home',
        component: () => import( '../views/home.vue'), //后台主体框架
        redirect: '/history/projectData',
        children: [
            {
                path: '/system/welcome',
                name: 'Welcome',
                component: () => import( '../views/welcome.vue'), //系统欢迎页
                meta:{
                    title:'Redmine管理后台'
                },
            },
            {
                path: '/system/password',
                name: 'password',
                component: () => import( '../views/system/password.vue'), //修改密码
                meta:{
                    title:'Redmine管理后台'
                },
            },
            {
                path: '/history/dataBoard',
                name: 'dataBoard',
                component: () => import( '../views/history/dataBoard'), //数据看板
                meta:{
                    title:'Redmine管理后台'
                },
            },
            {
                path: '/system/setup',
                name: 'setup',
                component: () => import( '../views/system/setup.vue'), //报表设置
                meta:{
                    title:'Redmine管理后台'
                },
            },
            {
                path: '/system/pageSetupInfo',
                name: 'pageSetupInfo',
                component: () => import( '../views/system/setupInfo.vue'), //报表详细设置
                meta:{
                    title:'Redmine管理后台'
                },
            },
            {
                path: '/system/companySigning',
                name: 'companySigning',
                component: () => import( '../views/system/companySigning.vue'), //公司签约
                meta:{
                    title:'Redmine管理后台'
                },
            },
            {
                path: '/history/projectData',
                name: 'projectData',
                component: () => import( '../views/history/projectData.vue'), //项目数据
                meta:{
                    title:'Redmine管理后台'
                },
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

import store from '../store'//引入store

//白名单页面
const whiteList=[
    "/system/password"
];

//路由导航守卫
router.beforeEach((to, from, next) => {
    const token = LocalStorage.get(LOCAL_KEY_ACCESS_TOKEN);
    if (to.path === '/login') {
        if (!token) {
            return next();
        } else {
            return next({path: '/home'})
        }
    }

    if (to.path === '/error/401') {
        return next();
    }

    let expireTime = LocalStorage.get("expiretime");
    if(expireTime != undefined && expireTime * 1 - new Date().getTime() > 0) {
        expireTime = new Date().getTime() + EXPIRETIME;
        LocalStorage.set("expiretime", expireTime);
    }
    let curTime = new Date().getTime();
    if (!token || expireTime != undefined && expireTime * 1 < curTime) {
        LocalStorage.clearAll();
        return router.push("/login");
    } else {
        return next();
    }
})


export default router
